import { render, staticRenderFns } from "./DataCategory.vue?vue&type=template&id=03e23bb7"
import script from "./DataCategory.vue?vue&type=script&setup=true&lang=ts"
export * from "./DataCategory.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DataCategory.vue?vue&type=style&index=0&id=03e23bb7&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionBackgroundCard: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionBackgroundCard.vue').default,CompositionSpacer: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,CompositionLayoutSingle: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/Composition.vue').default})
