import { render, staticRenderFns } from "./DataCategoryDeleteView.vue?vue&type=template&id=442697c2"
import script from "./DataCategoryDeleteView.vue?vue&type=script&setup=true&lang=ts"
export * from "./DataCategoryDeleteView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DataCategoryDeleteView.vue?vue&type=style&index=0&id=442697c2&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,Label: require('/app/node_modules/@align/ui/dist/runtime/molecules/Label/Label.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,TickBox: require('/app/node_modules/@align/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,CompositionSpacer: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,KeyValuePairContainerRow: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePairContainerRow.vue').default,PushButton: require('/app/node_modules/@align/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,Icon: require('/app/node_modules/@align/ui/dist/runtime/atoms/Icon/Icon.vue').default,KeyValuePair: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default})
